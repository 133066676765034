import { Component, OnInit, Inject } from "@angular/core";
import { environment } from "../../environments/environment";
import { FormBuilder } from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: 'app-checklist',
  templateUrl: "./checklist.component.html",
  styleUrls: ["./checklist.component.scss"],
})
export class ChecklistComponent implements OnInit {

  filterStatus : any = "all";
  markDoneItems: any = [];
  listParam: any = {};
  p: number = 0;

  itemsPerPage: number = 100;
  allCheckListItemsFetched: any = [];
  searchType: String;
  checkList: any = [];
  succ_msg: any;
  checkListId: number = 0;
  checkListIdForProcess: number = 0;
  checkListItemIdForProcess: number = 0
  sort_order: any;
  msgg: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  totalCheckList: any;

  priorities: any = ['High','Medium','Low'];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getCheckList();
  }

  onChange(value) {}

  openConfirmationDialog(event,c_id,parent_c_id) {
    event.stopPropagation();
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteCheckListItem(c_id, parent_c_id, confirmed))
      .catch(() => {});
  }

  deleteCheckListItem(c_id, parent_c_id, confirmed) {
    if (confirmed) {
      this.data.deleteCheckListItem(c_id).subscribe((response) => {
        
        if(parent_c_id > 0){
          let parentItemIndex = this.getChecklistIndex(parent_c_id);
          let itemIndex = this.getChecklistItemIndex(parent_c_id,c_id);
          this.checkList[parentItemIndex]['checklist_items'][itemIndex]['delete_status'] = 1
        }else{
          this.getCheckList();
        }

        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }

  markDone(event,parent_id,id){
    
    event.stopPropagation();

    return;
  
    let formdata = new FormData();
    formdata.append("checklist_id", id );

    this.data.markDone(formdata).subscribe((response:any) => {

      let parentItemIndex = this.getChecklistIndex(parent_id);
      let itemIndex = this.getChecklistItemIndex(parent_id,id);

      
      this.checkList[parentItemIndex]['checklist_items'][itemIndex]['done_status'] = response.item_done_status;

      if(response.total_checklist_items_count > 0){
        this.checkList[parentItemIndex]['done_status'] = 0;
      }else{
        this.checkList[parentItemIndex]['done_status'] = 1;
      }
    
    });
    
  }

  hideModal(type) {
    if(type == 'checklist'){
      this.getCheckList();
      this.getChecklistItems(this.checkListId);
    }
    this.checkListId = 0;
    jQuery("#checkListModal,#checklistProcessModal").modal("hide");
  }

  checkListEdit(id) {
    if (id != 0) {
      this.checkListId = id;
      jQuery("#checkListModal").modal("show");
    } else {
      this.checkListId = 0;
    }
  }

  filterList(value) {
    this.filterStatus = value;
    this.getCheckList();
  }

  addProcessFromChecklist(event,checklist_id) {
    event.stopPropagation();
    if (checklist_id != 0) {
      this.checkListIdForProcess = checklist_id;
      jQuery("#checklistProcessModal").modal("show");
    } else {
      this.checkListIdForProcess = 0;
    }
  }

  getChecklistIndex(checklistID){
    return this.checkList.findIndex((item) => {
      return item.c_id == checklistID
    });
  }

  getChecklistItemIndex(checklistID,checklistItemID){

    let checklistIndex =  this.getChecklistIndex(checklistID);

    if(checklistIndex == -1) return checklistIndex;

    return this.checkList[checklistIndex]['checklist_items'].findIndex((checkListItem) => {
      return checkListItem.c_id == checklistItemID
    });

  }

  getChecklistItems(checklistID = 0){
    if(checklistID == 0) return;

    let checklistIndex =  this.getChecklistIndex(checklistID)

    if(this.checkList[checklistIndex]['checklist_items_fetched']){
      delete this.checkList[checklistIndex]['checklist_items_fetched'];
      return;
    }
    
    this.data.getChecklistItems(checklistID).subscribe((data: any) => {

     let items = (data.items.length > 0) ? data.items : [];
     
     if(checklistIndex > -1){
        this.checkList[checklistIndex]['checklist_items'] = items;
        this.checkList[checklistIndex]['checklist_items_fetched'] = true;
      }
    });

    
  }
  getCheckList() {
    this.listParam.user_type_id = localStorage.getItem("UserType");
    this.listParam.filterStatus = this.filterStatus;

    this.data.getCheckList(this.listParam).subscribe((data:any) => {
  
      if (data.items.length > 0) {
        this.checkList = data.items;
        this.totalCheckList = data.totalRecords;
        this.p = data.pageNo;
        var dateSendingToServer = new DatePipe("en-US").transform( new Date(), "yyyy-MM-dd");
        for (let index = 0; index < this.checkList.length; index++) {

          // if(this.checkList[index].done_status == 1){
          //   this.markDoneItems.push(this.checkList[index].c_id)
          // }

          this.checkList[index].priority = this.priorities[this.checkList[index].priority];
          if ( this.checkList[index].due_date < dateSendingToServer && this.checkList[index].done_status != 1 ) {
            this.checkList[index].overdueFlag = 1;
          }
        }
      } else {
        this.checkList = [];
      }
    });
  }

  searchCheckList(value) {
    var s_key = jQuery("#searchKey").val();
    //this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getCheckList();
  }

  paginationFeed(page) {
    this.p = page;
    this.listParam.pageNo = this.p;
    this.getCheckList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getCheckList();
  }

  refresh() {
    window.location.reload();
  }
}
