import {
    Component,
    OnInit,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    EventEmitter,
  } from "@angular/core";
  import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
  import { DataService } from "../../services/data.service";
  import { Router, ActivatedRoute } from "@angular/router";
  import { DatePipe } from "@angular/common";
  import { DomSanitizer } from "@angular/platform-browser";
  import { AngularEditorConfig } from "@kolkov/angular-editor";

  declare var jQuery: any;
  
  @Component({
    selector: "app-add-checklist",
    templateUrl: "./add-checklist.component.html",
    styleUrls: ["./add-checklist.component.scss"],
  })
  export class AddChecklistComponent implements OnInit, OnChanges {
    addCheckListForm: FormGroup;
    checked: any = true;
    succ_msg: any;
    tid: number = 0;
   
    err_name: string;
    err_due_date: string;
    @Input() checkListId: number;
    readonlyMode: any = true;
    p: number = 0;
    FormHeading: string;
    listDetails: any;
    listParam: any = {};
    lastTaskId: any;
    readonly: boolean = false;
    checklistItems: any = [];

    subscribes: any = [];
    
    editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "150px",
    minHeight: "150px",
    maxHeight: "150px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(
      private fb: FormBuilder,
      private data: DataService,
      private router: Router,
      private route: ActivatedRoute,
      private sanitizer: DomSanitizer
    ) {}
  
    public ngOnChanges(changes: SimpleChanges): void {
    
      let chg = changes["checkListId"];
      let cval = chg.currentValue;
      if (chg.currentValue > "0" && chg.currentValue == cval) {
        this.listChecklistItems();
        this.editCheckList();
        this.FormHeading = "Edit Checklist";
      } else {
        this.FormHeading = "Add Checklist";
        this.checkListFormInit();
      }
    }
  
    ngOnInit() {
      this.checkListFormInit();
      this.FormHeading = "Add Checklist";
    }
  
    listChecklistItems(){
      if (this.subscribes["checklistItems"]) {
        this.subscribes["checklistItems"].unsubscribe();
      }
     
      this.subscribes["checklistItems"] = this.data
        .getChecklistItems(this.checkListId)
        .subscribe((data: any) => {
          let checklistItems = [];

          if (data.items.length > 0) {
            for (let item of data.items) {
              this.addchecklistFormItem();
              checklistItems.push({id:item.c_id,name: item.checklist_name,description:item.description})
            }

           
            this.addCheckListForm.patchValue({
              checklist_items: checklistItems
            });

          }
        });
    }
  
    editCheckList() {
      if (this.subscribes["checkListId"]) {
        this.subscribes["checkListId"].unsubscribe();
      }
  
      this.subscribes["checkListId"] = this.data
        .getChecklistDetails(this.checkListId)
        .subscribe((data) => {
          this.listDetails = data;
          
          this.addCheckListForm.patchValue({
            id: this.listDetails.c_id,
            name: this.listDetails.checklist_name,
          });
        });
    }
  
    addCheckList(postData) {
      let formdata = new FormData();

      formdata.append("id", postData.id);
      formdata.append("name", postData.name);
      formdata.append("checklist_items", JSON.stringify(this.addCheckListForm.controls['checklist_items'].value));
      formdata.append("add_from", "checklist");

      this.data.addCheckListpost(formdata).subscribe(
        (response) => {
          if (response) {
            this.succ_msg = response["msg"];
            this.blankErrorMsgs();
            this.editCheckList();
            setTimeout(() => {
              this.succ_msg = false;
              jQuery("#checkListModal").modal("hide");
              this.clickCancel();
              this.checkListFormInit();
            }, this.data.timeOut);
          } else {
            this.succ_msg = "";
          }
        },
  
        (error) => {

          this.err_name = (error.error.name) ? error.error.name : "";
        }
      );
    }

    blankErrorMsgs() {
      this.err_name =  "";
    }
  
    clickCancel() {

      this.closeModal.emit();
      this.blankErrorMsgs();

      if (this.checkListId == 0)  this.checkListFormInit();
      
    }

    getChecklistItemRowControl(){
        return  this.fb.group({
          id:"",
          name: "",
          description: "",
        });
    }
    addchecklistFormItem() {
      this.checklistItemsArray.push(this.getChecklistItemRowControl());
    }
    deletechecklistFormItem(index) {
      this.checklistItemsArray.removeAt(index);
    }
  
    get checklistItemsArray() {
      return this.addCheckListForm.get("checklist_items") as FormArray;
    }
    checkListFormInit() {
      this.addCheckListForm = this.fb.group({
        id: new FormControl(""),
        name: new FormControl(""),
        checklist_items: this.fb.array([]),
      });
    }
  }
  