import { Component, OnInit, Inject } from "@angular/core";
import { environment } from "../../environments/environment";
import { FormBuilder } from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.scss"],
})
export class TasksComponent implements OnInit {
  listParam: any = {};
  p: number = 0;

  itemsPerPage: number = 100;
  pstart: number = 0;
  pend: number = 100;
  allTaskrecords: any = [];
  activeOrg: boolean = false;
  activeP: boolean = true;
  searchType: String;
  taskList: any = [];
  succ_msg: any;
  taskId: number = 0;
  pagiId: number = 0;
  allActive: boolean = false;
  upcomingActive: boolean = false;
  completedActive: boolean = false;
  newActive: boolean = false;
  exportData: any;
  sort_order: any;
  exportUrl: String;
  assigneeList: any;
  state$: Observable<any>;
  msgg: any;
  assigneeType: String = "";
  sortDir = 1; //1= 'ASE' -1= DSC
  totalTask: any;
  checklist_process_id: any = 0;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {

    this.checklist_process_id = this.activatedRoute.snapshot.params["checklist_process_id"] ? this.activatedRoute.snapshot.params["checklist_process_id"] : 0;
    this.listParam.searchStringChecklistProcessId = this.checklist_process_id;
    
    this.getTaskList();
    //this.getTotalTask();

    this.allActive = true;

    this.data.getTaskAssignee(3).subscribe((data) => {
      this.assigneeList = data;
    });
    var uId = localStorage.getItem("UserId");
    this.exportUrl = `${environment.lpmsUrl}` + "task/exportTask/" + uId;

    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    this.state$.subscribe((res) => (this.msgg = res));

  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }

  openConfirmationDialog(tid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(tid, confirmed))
      .catch(() => {});
  }

  deleteListItem(tid, confirmed) {
    if (confirmed) {
      this.data.deleteListItem(tid).subscribe((response) => {
        this.getTaskList();
        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }

  hideModal() {
    this.taskId = 0;
    this.getTaskList();
    jQuery("#taskModal, #taskViewModal").modal("hide");
  }

  taskEdit(id) {
    if (id != 0) {
      this.taskId = id;

      if (this.msgg.addCreate === "popup") {
        jQuery("#taskModal").modal("show");
      }
    } else {
      this.taskId = 0;
    }
  }
  taskView(id) {
    this.taskId = id;
  }

  filterList(value) {
    if (value == "all") {
      this.allActive = true;
      this.upcomingActive = false;
      this.completedActive = false;
      this.newActive = false;
      this.listParam.searchStringStatus = "";
      this.listParam.date = "";
      this.getTaskList();
    } else if (value == "Upcoming") {
      this.allActive = false;
      this.upcomingActive = true;
      this.completedActive = false;
      this.newActive = false;
      this.listParam.searchStringStatus = value;
      this.getTaskList();
    } else if (value == "Completed") {
      this.allActive = false;
      this.upcomingActive = false;
      this.completedActive = true;
      this.newActive = false;
      this.listParam.searchStringStatus = value;
      this.getTaskList();
    } else if (value == "new") {
      this.allActive = false;
      this.upcomingActive = false;
      this.completedActive = false;
      this.newActive = true;
      this.listParam.searchStringStatus = value;
      this.getTaskList();
    } else if (value == "alltime") {
      this.listParam.date = value;
      this.getTaskList();
    } else if (value == "week") {
      this.listParam.date = value;
      this.getTaskList();
    } else if (value == "today") {
      this.listParam.date = value;
      this.getTaskList();
    } else if (value == "tomorrow") {
      this.listParam.date = value;
      this.getTaskList();
    } else if (value == "overdue") {
      this.listParam.date = value;
      this.getTaskList();
    } else {
      this.listParam.searchStringAssignee = value;
      this.getTaskList();
    }
  }

  getTaskList() {
    this.listParam.user_type_id = localStorage.getItem("UserType");

    this.data.getTaskList(this.listParam).subscribe((data) => {
      this.allTaskrecords = data;
      if (this.allTaskrecords.items.length > 0) {
        this.taskList = this.allTaskrecords.items;
        this.totalTask = this.allTaskrecords.totalRecords;
        this.p = this.allTaskrecords.pageNo;
        var myDate = new Date();
        var dateSendingToServer = new DatePipe("en-US").transform(
          myDate,
          "yyyy-MM-dd"
        );
        for (let index = 0; index < this.taskList.length; index++) {
          const element = this.taskList[index];
          if (
            this.taskList[index].due_date < dateSendingToServer &&
            this.taskList[index].task_status != "Completed"
          ) {
            this.taskList[index].overdueFlag = 1;
          }
        }
      } else {
        this.taskList = [];
      }
    });
  }

  searchTaskList(value) {
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getTaskList();
  }

  paginationFeed(page) {
    this.p = page;
    this.listParam.pageNo = this.p;
    this.getTaskList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getTaskList();
  }

  getTotalTask() {
    return new Promise((resolve, reject) => {
      this.data.getTotalTask(this.listParam).subscribe((data) => {
        this.totalTask = data;
      });
      resolve(null);
    });
  }

  refresh() {
    window.location.reload();
  }
}
