import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;
import { environment } from "../../environments/environment";

@Component({
  selector: "app-matters",
  templateUrl: "./matters.component.html",
  styleUrls: ["./matters.component.scss"],
})
export class MattersComponent implements OnInit {
  listParam: any = {};
  p: number = 0;
  itemsPerPage: number = 100;
  pstart: number = 0;
  pend: number = 100;

  sort_order: any;
  matterList: any = [];
  matterCount: any;
  exportMatterUrl: String;
  exportMatterIApplicationsUrl: String;

  allActive: boolean = false;
  openActive: boolean = false;
  closedActive: boolean = false;
  pendingActive: boolean = false;
  ongoingActive: boolean = false;
  succ_msg: any;
  other_flag: boolean = true;
  state$: Observable<any>;
  msgg: any;
  matterDetails: any;
  case_type: any;
  cId: any;
  matterId: number = 0;
  sortDir = 1; //1= 'ASE' -1= DSC

  subs: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.subs["states"]) {
      this.subs["states"].unsubscribe();
    }
    let url = window.location.href;
    this.cId = url.substr(url.lastIndexOf("/") + 1);
    if (!isNaN(this.cId)) {
      this.listParam.searchString = this.cId;
      this.listParam.selectedSearchKey = "Client Id";
      this.getMatterList();
    }

    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    this.subs["states"] = this.state$.subscribe((res) => (this.msgg = res));
    if (this.msgg.clientID && !isNaN(this.msgg.clientID)) {
      this.cId = this.msgg.clientID;
      this.listParam.searchString = this.cId;
      this.listParam.selectedSearchKey = "Client Id";
      this.getMatterList();
    }

    // For client matter show all
    if (
      this.msgg.clientMatters &&
      this.msgg.clientMatters == "yes" &&
      this.msgg.clientNumber &&
      this.msgg.clientNumber != ""
    ) {
      this.listParam.searchString = this.msgg.clientNumber;
      this.listParam.selectedSearchKey = "Client Number";
      this.getMatterList();
      jQuery(".modal-backdrop").hide();
    }

    $(document).ready(function () {
      $(".toggle").click(function () {
        $(this).toggleClass("active");
        $(this).next().toggle("fast");
        return false;
      });

      $(".hide").click(function () {
        $(this).toggleClass("active");
        $(this).parent().parent().parent().parent().toggleClass("active");
        return false;
      });
    });

    this.getMatterList();
    //this.getMatterCount();
    this.allActive = true;
    //this.startAndEndOfWeek(new Date());
    var uId = localStorage.getItem("UserId");
    //this.exportMatterUrl = `${environment.lpmsUrl}`+"matters/exportMatters/"+uId;
    (this.exportMatterUrl = `${environment.lpmsUrl}` + "matters/exportMatters"),
      { params: this.listParam };

    this.getCaseTypeList();
  }

  getCaseTypeList() {
    if (this.subs["case"]) {
      this.subs["case"].unsubscribe();
    }
    this.subs["case"] = this.data.getTypeOfCase().subscribe((data) => {
      this.case_type = data;
    });
  }

  filterList(value) {
    if (value == "all") {
      this.allActive = true;
      this.openActive = false;
      this.closedActive = false;
      this.pendingActive = false;
      this.ongoingActive = false;
      this.listParam.searchString = "";
      this.listParam.searchStringStatus = "";
      this.getMatterList();
    } else if (value == "open") {
      this.allActive = false;
      this.openActive = true;
      this.closedActive = false;
      this.pendingActive = false;
      this.ongoingActive = false;
      this.listParam.searchStringStatus = value;
      this.getMatterList();
    } else if (value == "closed") {
      this.allActive = false;
      this.openActive = false;
      this.closedActive = true;
      this.pendingActive = false;
      this.ongoingActive = false;
      this.listParam.searchStringStatus = value;
      this.getMatterList();
    } else if (value == "pending") {
      this.allActive = false;
      this.openActive = false;
      this.closedActive = false;
      this.pendingActive = true;
      this.ongoingActive = false;
      this.listParam.searchStringStatus = value;
      this.getMatterList();
    } else if (value == "ongoing") {
      this.allActive = false;
      this.openActive = false;
      this.closedActive = false;
      this.pendingActive = false;
      this.ongoingActive = true;
      this.listParam.searchStringStatus = value;
      this.getMatterList();
    }
  }

  getMatterList() {
    if (this.subs["mList"]) {
      this.subs["mList"].unsubscribe();
    }

    this.listParam.pstart = this.pstart;
    this.listParam.pend = this.pend;
    this.subs["mList"] = this.data
      .getMatterList(this.listParam)
      .subscribe((data) => {
        this.matterList = data;
        this.getMatterCount();
      });
  }

  getMatterCount() {
    if (this.subs["mCount"]) {
      this.subs["mCount"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["mCount"] = this.data
        .countMatter(this.listParam)
        .subscribe((data) => {
          this.matterCount = data;
        });
      resolve(null);
    });
  }

  searchTaskList(value) {
    if (this.listParam.type_of_case) {
      this.listParam.type_of_case = this.listParam.type_of_case;
    } else {
      this.listParam.type_of_case = 0;
    }
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getMatterList();
    //this.getMatterCount();
  }

  caseList(value) {
    this.exportMatterIApplicationsUrl = '';
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;

    if (this.listParam.searchString) {
      this.listParam.searchString = this.listParam.searchString;
    }
    this.listParam.type_of_case = value;
    this.getMatterList();

    if(value == 1){
      this.exportMatterIApplicationsUrl = `${environment.lpmsUrl}` + "matters/exportMatterApplications?type_of_case=" + value;
    }
   

  }

  paginationFeed(page) {
    this.p = page;
    this.listParam.pageNo = page - 1;

    this.pstart = this.itemsPerPage * this.p - this.itemsPerPage;
    this.pend = this.itemsPerPage * this.p;

    this.listParam.pstart = this.pstart;
    this.listParam.pend = this.pend;
    //this.getMatterCount();
    this.getMatterList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getMatterList();
  }

  openConfirmationDialog(matter_id) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(matter_id, confirmed))
      .catch(() => {});
  }

  deleteListItem(matter_id, confirmed) {
    if (this.subs["dMatterDtl"]) {
      this.subs["dMatterDtl"].unsubscribe();
    }
    this.subs["dMatterDtl"] = this.data
      .getMatterDetails(matter_id)
      .subscribe((data) => {
        this.matterDetails = data;
      });
    if (confirmed) {
      if (this.subs["dMatter"]) {
        this.subs["dMatter"].unsubscribe();
      }
      if (this.subs["dMatterND"]) {
        this.subs["dMatterND"].unsubscribe();
      }
      this.subs["dMatter"] = this.data
        .deleteAllMatter(matter_id)
        .subscribe((response) => {
          if (this.matterDetails.type_of_case == 1) {
            var team_code = "IP";
          } else {
            var team_code = "CORPORATE";
          }
          this.subs["dMatterND"] = this.data
            .deleteAllMatterFromND(
              this.matterDetails.client_number,
              this.matterDetails.matter_number,
              team_code
            )
            .subscribe((response) => {});
          this.getMatterList();
          this.succ_msg = "Deleted Successfully";
          setTimeout(() => {
            this.succ_msg = "";
            this.succ_msg = false;
          }, this.data.timeOut);
        });
    }
  }

  matterEdit(id) {
    if (id != 0) {
      this.matterId = id;
      this.other_flag = false;
    } else {
      this.matterId = 0;
      this.other_flag = true;
      jQuery("#createMatterModal").on("hide.bs.modal", () => {
        this.getMatterList();
      });
    }
  }

  hideModal() {
    this.matterId = 0;
    this.other_flag = true;
    this.getMatterList();
    jQuery("#createMatterModal").modal("hide");
  }

  refresh() {
    window.location.reload();
  }
}
