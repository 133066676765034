import { Component, OnInit, Inject } from "@angular/core";
import { environment } from "../../../environments/environment";
import { FormBuilder } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: "app-checklist-process",
  templateUrl: "./checklist-process.component.html",
  styleUrls: ["./checklist-process.component.scss"],
})
export class ChecklistProcessComponent implements OnInit {
  listParam: any = {};
  p: number = 0;

  itemsPerPage: number = 100;
  pstart: number = 0;
  pend: number = 100;
  allChecklistProcessRecords: any = [];
  activeOrg: boolean = false;
  activeP: boolean = true;
  searchType: String;
  checklistProcessList: any = [];
  checkList: any = [];
  selectedChecklist: any = 0;
  selectedChecklistItem: any = 0;
  succ_msg: any;
  checklistProcessId: number = 0;
  pagiId: number = 0;
  allActive: boolean = false;
  upcomingActive: boolean = false;
  completedActive: boolean = false;
  newActive: boolean = false;
  exportData: any;
  sort_order: any;
  exportUrl: String;
  assigneeList: any;
  state$: Observable<any>;
  msgg: any;
  assigneeType: String = "";
  sortDir = 1; //1= 'ASE' -1= DSC
  totalChecklistProcess: any;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {

    this.allActive = true;

    this.listParam.searchStringChecklist = this.activatedRoute.snapshot.params["checklist_id"] ? this.activatedRoute.snapshot.params["checklist_id"] : '';
    this.selectedChecklist = this.activatedRoute.snapshot.params["checklist_id"] ? this.activatedRoute.snapshot.params["checklist_id"] : 0;
    
    this.getChecklistProcessList();
    this.getCheckList();

    this.data.getTaskAssignee(3).subscribe((data) => {
      this.assigneeList = data;
    });

  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }

  openConfirmationDialog(tid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(tid, confirmed))
      .catch(() => {});
  }

  deleteListItem(tid, confirmed) {
    if (confirmed) {
      this.data.deleteChecklistProcess(tid).subscribe((response) => {
        this.getChecklistProcessList();
        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }

  hideModal() {
    this.checklistProcessId = 0;
    this.getChecklistProcessList();
    jQuery("#checklistProcessModal, #checklistProcessViewModal").modal("hide");
  }

  checklistProcessEdit(id) {
    if (id != 0) {
      this.checklistProcessId = id;
    } else {
      this.checklistProcessId = 0;
    }
  }
  checklistProcessView(id) {
    this.checklistProcessId = id;
  }

  filterListByChecklist(value){
    
    this.listParam.searchStringChecklist = this.selectedChecklist = value;
    this.getChecklistProcessList();
  }
  filterList(value) {
    if (value == "all") {
      this.allActive = true;
      this.upcomingActive = false;
      this.completedActive = false;
      this.newActive = false;
      this.listParam.searchStringStatus = "";
      this.listParam.date = "";
      this.getChecklistProcessList();
    } else if (value == "Upcoming") {
      this.allActive = false;
      this.upcomingActive = true;
      this.completedActive = false;
      this.newActive = false;
      this.listParam.searchStringStatus = value;
      this.getChecklistProcessList();
    } else if (value == "Completed") {
      this.allActive = false;
      this.upcomingActive = false;
      this.completedActive = true;
      this.newActive = false;
      this.listParam.searchStringStatus = value;
      this.getChecklistProcessList();
    } else if (value == "new") {
      this.allActive = false;
      this.upcomingActive = false;
      this.completedActive = false;
      this.newActive = true;
      this.listParam.searchStringStatus = value;
      this.getChecklistProcessList();
    } else if (value == "alltime") {
      this.listParam.date = value;
      this.getChecklistProcessList();
    } else if (value == "week") {
      this.listParam.date = value;
      this.getChecklistProcessList();
    } else if (value == "today") {
      this.listParam.date = value;
      this.getChecklistProcessList();
    } else if (value == "tomorrow") {
      this.listParam.date = value;
      this.getChecklistProcessList();
    } else if (value == "overdue") {
      this.listParam.date = value;
      this.getChecklistProcessList();
    } else {
      this.listParam.searchStringAssignee = value;
      this.getChecklistProcessList();
    }
  }

  getChecklistProcessList() {
    this.listParam.user_type_id = localStorage.getItem("UserType");
    this.listParam.time = Date.now();

    this.data.getChecklistProcessList(this.listParam).subscribe((data : any) => {
      
      if (data.totalRecords ==  0) this.checklistProcessList = [];
      else this.checklistProcessList = (data.items.length > 0) ? data.items : [];

    });
  }

  getCheckList() {
    let param = new FormData();
    this.data.getCheckList(param).subscribe((data:any) => {
      this.checkList = (data.items.length > 0) ? data.items : [];
    })
  }

  searchTaskList(value) {
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getChecklistProcessList();
  }

  paginationFeed(page) {
    this.p = page;
    this.listParam.pageNo = this.p;
    this.getChecklistProcessList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getChecklistProcessList();
  }

  getTotalTask() {
    return new Promise((resolve, reject) => {
      this.data.getTotalTask(this.listParam).subscribe((data) => {
        this.totalChecklistProcess = data;
      });
      resolve(null);
    });
  }

  refresh() {
    window.location.reload();
  }
}
