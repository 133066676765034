import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.scss"],
})
export class ApplicationComponent implements OnInit {
  listParam: any = {};
  p: number = 0;
  itemsPerPage: number = 20;
  applicationList: any = [];
  sort_order: any;
  parentExample: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private data: DataService,
    private router: Router
  ) {
    this.route.parent.params.subscribe((params) => console.log(params));
  }

  ngOnInit() {
    this.getAppListFromFilemaker();
  }

  getAppListFromFilemaker() {
    this.data.getAppListFromFilemaker(this.listParam).subscribe((response) => {
      if (response) {
        this.applicationList = response;
      }
    });
  }

  valueChanged(val) {
    this.parentExample = val;
  }

  searchTaskList(value) {
    this.listParam.searchString = value;
    this.getAppListFromFilemaker();
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.listParam.pageNo = page - 1;
    this.getAppListFromFilemaker();
  }

  sort(val: any) {
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getAppListFromFilemaker();
  }
}
