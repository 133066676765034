import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  RoutesRecognized,
} from "@angular/router";
import "rxjs/add/operator/filter";
import * as $ from "jquery";
import "rxjs/add/operator/pairwise";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  mId: any;
  bnlawyers: boolean = false;
  bnoperations: boolean = false;
  bnip: boolean = false;
  dashboardMenu: string;
  constructor(private router: Router, private data: DataService) {
    this.router.events.pairwise().subscribe((event) => {
      $(document).ready(function () {
        $(".hasChild a").removeClass("open");
        $(".leftmenu li a").find(".fa").removeClass("fa-angle-down");
        $(".hasChild a").next().removeClass("show");
        $(".hasChild a").next().slideUp("fast");
        $(".leftmenu li ul").hide();
        $(".leftmenu li a").removeClass("open");
        $("html, body").animate({ scrollTop: 0 }, "fast");
      });
    });

    this.router.events
      .filter((event) => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        let url = event.url;
        if (
          url == "/bnlawyers" ||
          url == "/matter-list/ongoing_matters" ||
          url == "/matter-list/filed_matters" ||
          url == "/trademark-list/granted_trademarks" ||
          url == "/trademark-list/granted_trademarks"
        ) {
          this.bnlawyers = true;
          this.bnoperations = false;
          this.bnip = false;
        } else if (
          url == "/bnip" ||
          url == "/application" ||
          url == "/unpublishedtm"
        ) {
          this.bnlawyers = false;
          this.bnoperations = false;
          this.bnip = true;
        } else if (url == "/bnoperations" || url == "/leads") {
          this.bnlawyers = false;
          this.bnoperations = true;
          this.bnip = false;
        }

        if (
            url == "/trademark-list/granted_trademarks" ||
          url == "/trademark-list/granted_trademarks" ||
            url == "/checklist" ||
            url == "/checklist-process-list" ||
          url == "/matters" ||
          url == "/matter-list" ||
          url == "/matters" ||
          url == "/bnoperations" ||
          url == "/bnip" ||
          url == "/bnlawyers" ||
          url == "/clients" ||
          url == "/knowledge-base" ||
          url == "/tasks" ||
          url == "/globalcalendar" ||
          url == "/contacts" ||
          url == "/agenda" ||
          url == "/ipcalendar" ||
          url == "/bncalendar" ||
          url == "/documents" ||
          url == "/emails"
        ) {
          document.querySelector("body").classList.add("sidebar-toggle");
        }

        // if(event.id > 0){
        // 	document.querySelector('.leftmenu li ul').style.visibility = 'hidden'
        // 	document.querySelector('.leftmenu li a').classList.remove('open')
        // }
      });
  }

  ngOnInit() {
    this.bnlawyers = true;
    $(document).ready(function () {
      $(".hasChild a").click(function () {
        $(".leftmenu li ul").slideUp("fast");
        $(".leftmenu li a").removeClass("open");
        $(".leftmenu li a").find(".fa").removeClass("fa-angle-down");
        var $this = $(this);

        if ($this.next().hasClass("show")) {
          $this.next().removeClass("show");
          $this.next().slideUp("fast");
          $this.removeClass("open");
          $this.find(".fa").removeClass("fa-angle-down");
        } else {
          $this.addClass("open");
          $this.next().toggleClass("show");
          $this.next().slideToggle("fast");
          $this.find(".fa").addClass("fa-angle-down");
        }
        return false;
      });
    });

    this.data.userTypeId = localStorage.getItem("UserType");

    if (this.data.userTypeId == 1) {
      this.dashboardMenu = "bnoperations";
    } else if (this.data.userTypeId == 3) {
      this.dashboardMenu = "bnlawyers";
    } else {
      this.dashboardMenu = "bnip";
    }

    window.onresize = function () {
      if (window.innerWidth < 1399) {
        document.querySelector("body").classList.add("sidebar-toggle");
      }
    };
  }
}
