import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BnlawComponent } from './dashboard/bnlawyers/bnlaw.component';
import { BnoperationsComponent } from './dashboard/bnoperations/bnoperations.component';
import { BnipComponent } from './dashboard/bnip/bnip.component';
import { ClientsComponent } from './contacts/clients/clients.component';
import { MattersComponent } from './matters/matters.component';
import { LoginComponent } from './login/login.component';
import { GlobalCalendarComponent } from './calendar/global-calendar/global-calendar.component';
import { TasksComponent } from './tasks/tasks.component';
import { EmailsComponent } from './emails/emails.component';
import { ComposemailComponent } from './emails/composemail/composemail.component';
import { DocumentsComponent } from './documents/documents.component';
import { ContactsComponent } from './contacts/contacts.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { AuthGuardService as AuthGuard  } from './services/auth-guard.service';
import { ReportsDashboardComponent } from './reports/reports-dashboard/reports-dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { QuotesComponent } from './quotes/quotes.component';
import { LeadComponent } from './lists/lead/lead.component';
import { LeadsEngagedComponent } from './leads/leads-engaged/leads-engaged.component';
import { AssignTaskComponent } from './leads/assign-task/assign-task.component';
import { ScheduleCallComponent } from './leads/schedule-call/schedule-call.component';
import { GenerateQuoteComponent } from './leads/generate-quote/generate-quote.component';
import { AgreementSignedComponent } from './leads/agreement-signed/agreement-signed.component';
import { VerifyCompanyComponent } from './leads/verify-company/verify-company.component';
import { RegisteredClientComponent } from './leads/registered-client/registered-client.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatterDashboardComponent } from './matters/matter-dashboard/matter-dashboard.component';
import { MatterNotesComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-notes/matter-notes.component';
import { MatterWorkflowComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-workflow/matter-workflow.component';
import { MatterTasksComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-tasks/matter-tasks.component';
import { MatterEmailComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-email/matter-email.component';
import { MatterFilesComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-files/matter-files.component';
import { LeadsHomeComponent } from './leads/leads-home.component';
import { TaskListsComponent } from './tasks/task-lists/task-lists.component';

import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { UnpublishedtmComponent } from './lists/unpublishedtm/unpublishedtm.component';
import {InlineTranslationComponent} from './inline-translation/inline-translation.component';
import { AgendaComponent } from './calendar/agenda/agenda.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {FilemakerApplicationComponent} from './filemaker-application/filemaker-application.component';

import { ChangePasswordComponent } from './user-management/change-password/change-password.component';
import { RedirectGuard } from "./services/redirect.guard.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { CreateUserComponent } from './user-management/create-user/create-user.component';

import { environment } from '../environments/environment';

import { MatterListsComponent } from './lists/matter-lists/matter-lists.component';
import { MatterTrademarksComponent } from './lists/matter-trademark/matter-trademark.component';

import { ArticleDetailsComponent } from './knowledge-base/article-details/article-details.component';

import { ReportsMatterComponent } from './reports/reports-matter/reports-matter.component';
import { ReportsClientComponent } from './reports/reports-client/reports-client.component';
import { ReportsTaskComponent } from './reports/reports-task/reports-task.component';
import { ReportsQuoteComponent } from './reports/reports-quote/reports-quote.component';
import { ProcessStListComponent } from './tasks/process-st-list/process-st-list.component';
import { ReportsOperationsComponent } from './reports/reports-operations/reports-operations.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistProcessComponent } from './checklist/checklist-process/checklist-process.component';




export const Approutes: Routes = [  
  {path: '', redirectTo: 'bnlawyers', pathMatch: 'full'},
  { path: 'bnlawyers', component: BnlawComponent,canActivate: [AuthGuard] },
  { path: 'bnoperations', component: BnoperationsComponent,canActivate: [AuthGuard]},
  { path: 'bnip', component: BnipComponent,canActivate: [AuthGuard] },
  { path: 'clients', component: ClientsComponent,canActivate: [AuthGuard]},
  { path: 'matters', component: MattersComponent,canActivate: [AuthGuard]},
  { path: 'globalcalendar', component: GlobalCalendarComponent,canActivate: [AuthGuard]},
  { path: 'ipcalendar', component: GlobalCalendarComponent,canActivate: [AuthGuard]},
  { path: 'bncalendar', component: GlobalCalendarComponent,canActivate: [AuthGuard]},
  { path: 'agenda', component: AgendaComponent,canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent},
  { path: 'reset-password', component: ResetPasswordComponent},

  { path: 'checklist', component: ChecklistComponent,canActivate: [AuthGuard]},
  { path: 'checklist-process-list/:checklist_id', component: ChecklistProcessComponent,canActivate: [AuthGuard]},
  { path: 'checklist-process-list', component: ChecklistProcessComponent,canActivate: [AuthGuard]},

  
  
  { path: 'tasks/:checklist_process_id', component: TasksComponent,canActivate: [AuthGuard]},
  { path: 'tasks', component: TasksComponent,canActivate: [AuthGuard]},
  { path: 'emails/compose', component: ComposemailComponent,canActivate: [AuthGuard]},
  { path: 'emails', component: EmailsComponent,canActivate: [AuthGuard]},
  { path: 'emails/:id', component: EmailsComponent,canActivate: [AuthGuard]},
  { path: 'emails/compose/:tempId', component: ComposemailComponent,canActivate: [AuthGuard]},
  { path: 'documents', component: DocumentsComponent,canActivate: [AuthGuard]},
  { path: 'contacts', component: ContactsComponent,canActivate: [AuthGuard]},
  { path: 'knowledge-base', component: KnowledgeBaseComponent,canActivate: [AuthGuard]},
  { path: 'knowledge-base/article/:aId', component: ArticleDetailsComponent},
  { path: 'tasks', component: TasksComponent,canActivate: [AuthGuard]},
  { path: 'matters/matterList/Client Id/:cId', component: MattersComponent,canActivate: [AuthGuard]},
  { path: 'matters/matterList/caseType/:cType', component: MattersComponent,canActivate: [AuthGuard]},
  /* { path: 'documents', component: DocumentsComponent,canActivate: [AuthGuard]}, */
  {path: "not-found/documents", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=open_doc_list&from_lpms=1&from_other=1"
    }
  },
  { path: 'contacts', component: ContactsComponent,canActivate: [AuthGuard]},
  { path: 'knowledge-base', component: KnowledgeBaseComponent,canActivate: [AuthGuard]},
  { path: 'reports', component: ReportsDashboardComponent,canActivate: [AuthGuard]},

  { path: 'reports-matter', component: ReportsMatterComponent,canActivate: [AuthGuard]},
  { path: 'reports-client', component: ReportsClientComponent,canActivate: [AuthGuard]},
  { path: 'reports-task', component: ReportsTaskComponent,canActivate: [AuthGuard]},
  { path: 'reports-quote', component: ReportsQuoteComponent,canActivate: [AuthGuard]},
  { path: 'reports-operations', component: ReportsOperationsComponent,canActivate: [AuthGuard]},

  { path: 'quotes', component: QuotesComponent,canActivate: [AuthGuard]},
  { path: 'inline-translation', component: InlineTranslationComponent,canActivate: [AuthGuard]},
  /* { path: 'user-management/:viewType', component: UserManagementComponent,canActivate: [AuthGuard]}, */
  {path: "not-found/matter-menu", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=matter_dashboard_application&form_id=26&rID=6064&pre_form_id=25&pre_row_id=1048&breadcum=M&client_number=2010&mId=5"
    }
  },
  {path: "not-found/cms", canActivate: [RedirectGuard], component: NotFoundComponent,
  data: {
    externalUrl: `${environment.filemakerUrl}`+"mails"
  }
},
  {path: "not-found/userList", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"administrators/?action=user_management"
    }
  },
  {path: "not-found/userType", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"administrators/?action=manage_user_type"
    }
  },
  {path: "not-found/userGroup", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"administrators/?action=manage_user_group"
    }
  },
  
  {path: "not-found/account", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"profile/?action=edit_profile"
    }
  },
  { path: 'leads', component: LeadComponent,canActivate: [AuthGuard]},
  { path: 'leads/leads-home', component: LeadsHomeComponent,
    children:[
      { path: 'leads-engaged/:lId', component: LeadsEngagedComponent,canActivate: [AuthGuard]},
      { path: 'assign-task/:lId', component: AssignTaskComponent,canActivate: [AuthGuard]},
      { path: 'schedule-call/:lId', component: ScheduleCallComponent,canActivate: [AuthGuard]},
      { path: 'generate-quote/:lId', component: GenerateQuoteComponent,canActivate: [AuthGuard]},
      { path: 'agreement-signed/:lId', component: AgreementSignedComponent,canActivate: [AuthGuard]},
      { path: 'verify-company/:lId', component: VerifyCompanyComponent,canActivate: [AuthGuard]},
      { path: 'registered-client/:lId', component: RegisteredClientComponent,canActivate: [AuthGuard]},
    ]
  },

  /* { path: 'filemaker-application/:viewType', component: FilemakerApplicationComponent,canActivate: [AuthGuard]}, */
  {path: "not-found/application", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=list_table_view&block_id=38"
    }
  },
  {path: "not-found/unpublishedtm", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=unpublished_trademarks"
    }
  },
  {path: "not-found/unpublishedOther", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=unpublished_other_actions"
    }
  },
  {path: "not-found/Ungranted", canActivate: [RedirectGuard], component: NotFoundComponent,
    data: {
      externalUrl: `${environment.filemakerUrl}`+"table/?action=ungranted_trademarks"
    }
  },

/*   { path: 'filemaker-application/:viewType', component: FilemakerApplicationComponent,canActivate: [AuthGuard]}, */
  { path: 'matter-list/:viewType', component: MatterListsComponent,canActivate: [AuthGuard]},
  { path: 'trademark-list/:viewType', component:  MatterTrademarksComponent ,canActivate: [AuthGuard]},
 

  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'matters/matter-home', component: MatterDashboardComponent,
    children:[
      { path: 'matter-notes/:mId', component: MatterNotesComponent,canActivate: [AuthGuard]},
      { path: 'matter-workflow/:mId', component: MatterWorkflowComponent,canActivate: [AuthGuard]},
      { path: 'matter-tasks/:mId/:id', component: MatterTasksComponent,canActivate: [AuthGuard]},
      { path: 'matter-tasks/:mId', component: MatterTasksComponent,canActivate: [AuthGuard]},
      { path: 'matter-email/:mId', component: MatterEmailComponent,canActivate: [AuthGuard]},
      { path: 'matter-files/:mId', component: MatterFilesComponent,canActivate: [AuthGuard]},
      { path: 'matter-application/:mId', component: UserManagementComponent,canActivate: [AuthGuard]},
    ]
  },

  {path: 'task-lists', component: TaskListsComponent, canActivate: [AuthGuard]},
  {path: 'process-st-lists', component: ProcessStListComponent, canActivate: [AuthGuard]},
  {path: 'custom-fields', component: CustomFieldsComponent, canActivate: [AuthGuard]},
  {path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'create-user', component: CreateUserComponent, canActivate: [AuthGuard]},
 
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(Approutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
