import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataService } from "../../services/data.service";
import { MatInput } from '@angular/material';

@Component({
  selector: "app-matter-trademark",
  templateUrl: "./matter-trademark.component.html",
  styleUrls: ["./matter-trademark.component.scss"],
})
export class MatterTrademarksComponent implements OnInit {
  viewType: any;
  IpApplicationTitle: any;
  IpApplicationsList: any = [];
  sort_order: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  IpApplicationsCount: any;

  trademarkUpdateDates: any = [];

  listParam: any = {};
  p: number = 0;
  itemsPerPage: number = 50;
  pstart: number = 0;
  pend: number = 50;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private data: DataService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params) {
        if (params["viewType"]) {
          this.viewType = params["viewType"];
          if (this.viewType == "granted_trademarks") {
            this.IpApplicationTitle = "Granted Matters";
          } else if (this.viewType == "published_trademarks") {
            this.IpApplicationTitle = "Published Matters";
          }
          this.p = 1;
          this.pstart = 0;
          this.listParam.filterType = this.viewType;
          this.getTrademarkList();
        }
      }
    });
    
  }

  updateIpApplicationDates(){
    
    this.data.updateIpApplicationDates({items: this.trademarkUpdateDates,change_type: this.viewType}).subscribe((data: any) => {
      
      for (let item of this.trademarkUpdateDates) {

      
        let deleteIndex =  this.IpApplicationsList.findIndex((IpItem) => {
           return item.filling_number == IpItem.filling_number
         })
         
         if (deleteIndex > -1) this.IpApplicationsList.splice(deleteIndex, 1);
       }     
      
    
       this.trademarkUpdateDates = [];
      if(this.IpApplicationsList.length == 0){
        this.getTrademarkList();
      }
    });
  }
 
  dateChanged(trademarkItem,event){
    if(event){
      let index =  this.trademarkUpdateDates.findIndex((item) => {
        return item.filling_number == trademarkItem.filling_number
      })
      
      if (index > -1) return;
      
      this.trademarkUpdateDates.push({
        date: event.value.format('YYYY-MM-DD'),trademark_id: trademarkItem.id,
        filling_number: trademarkItem.filling_number,client_number: trademarkItem.client_number
      });
    }
  }

  resetPicker(){}

  getTrademarkList() {
    this.listParam.pstart = this.pstart;
    this.listParam.pend = this.pend;
    this.data.getTrademarkList(this.listParam).subscribe((data) => {
      this.IpApplicationsList = data;
      this.getIpApplicationsCount();
    });
  }

  getIpApplicationsCount() {
    this.data.countIpApplications(this.listParam).subscribe((data) => {
      this.IpApplicationsCount = data;
    });
  }

  searchApplicationList(value) {
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.p = 0;
    this.pstart = 0;
    
    this.getTrademarkList();
  }

  paginationFeed(page) {
    this.p = page;
    this.listParam.pageNo = page - 1;

    this.pstart = this.itemsPerPage * this.p;
    this.pend = this.itemsPerPage;

    this.listParam.start = this.pstart;
    this.listParam.end = this.pend;
    this.getTrademarkList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getTrademarkList();
  }

  refresh() {
    window.location.reload();
  }
}
