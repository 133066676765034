import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
declare var jQuery: any;
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-add-checklist-process",
  templateUrl: "./add-checklist-process.component.html",
  styleUrls: ["./add-checklist-process.component.scss"],
})
export class AddChecklistProcessComponent implements OnInit, OnChanges {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  assigneeList: any;
  clientList: any;
  checklist: any;
  checklistItems: any;
  taskListID: any;
  matterList: any;
  err_checklist_id: string;
  err_checklist_item_id: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_client_id: string;
  err_matter_id: string;

  @Input() checklistProcessId: number;
  @Input() selectedChecklist: number;
  @Input() selectedChecklistItem: number;
  p: number = 0;
  FormHeading: string;
  listDetails: any;
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;
  assigneeType: String = "";
  readonly: boolean = false;

  matterTypeId: any;
  matterDtl: any;
  testbutton: boolean = true;
  selectedClient: any;
  selectedMatter: any;
  selectedUser: any;
  subscribes: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "400px",
    minHeight: "260px",
    maxHeight: "260px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
  
    if(changes["checklistProcessId"] && changes["checklistProcessId"].currentValue > 0 ){
      this.editTaskList();
      this.FormHeading = "Edit Process";
    }else{
      this.FormHeading = "Add Process";
      this.taskFormInit();
      this.listCheckListItems(0);
      this.getLastProcessId();
    }
    
      
    if(  changes["selectedChecklist"] && changes["selectedChecklist"].currentValue > 0){
      this.addTaskForm.controls["checklist_id"].setValue(changes["selectedChecklist"].currentValue);
      setTimeout(() => { this.listCheckListItems(changes["selectedChecklist"].currentValue) }, 500);
    }

  }

  ngOnInit() {
    this.taskFormInit();
    this.getLastProcessId();
    this.listClientList();
    this.listCheckList();
    this.listCheckListItems(0);

    this.FormHeading = "Add Process";
  
  }
 

  getLastProcessId() {
    
    if (this.subscribes["lastTask"]) {
      this.subscribes["lastTask"].unsubscribe();
    }
    this.subscribes["lastTask"] = this.data
      .getLastProcessId()
      .subscribe((data) => {
        this.lastTaskId = data;
        let numb = this.lastTaskId.toString().replace("P", "");
        let digit = Number(numb);
        if (digit > 0) {
          this.addTaskForm.controls["process_id"].setValue(this.lastTaskId);
        }else {
          this.addTaskForm.controls["process_id"].setValue("P1");
        }
      });
  }

  getTaskList() {
    if (this.subscribes["taskList"]) {
      this.subscribes["taskList"].unsubscribe();
    }
    this.subscribes["taskList"] = this.data
      .getTaskList(this.listParam)
      .subscribe((data) => {
        this.taskList = data;
      });
  }

  editTaskList() {
    if (this.subscribes["taskListId"]) {
      this.subscribes["taskListId"].unsubscribe();
    }

    this.subscribes["taskListId"] = this.data
      .getlistDetailsCheklistProcess(this.checklistProcessId)
      .subscribe((data) => {
        this.listDetails = data;
        let logInId = localStorage.getItem("UserId");

        if (this.listDetails.process_id) {
          this.testbutton = false;
        } else {
          this.testbutton = true;
        }

        this.listMatterList(this.listDetails);
        setTimeout(() => { this.listCheckListItems(this.listDetails.checklist_id) }, 500);

        this.addTaskForm.patchValue({
          p_id: this.listDetails.p_id,
          process_id: this.listDetails.process_id,
          checklist_id: (this.listDetails.checklist_id > 0) ? this.listDetails.checklist_id : '',
          checklist_item_id: (this.listDetails.checklist_item_id > 0) ? this.listDetails.checklist_item_id : '',
          process_description: this.listDetails.description,
          process_due_date: this.listDetails.due_date,
          process_assignee: this.listDetails.assignee_type,
          process_assignee_user: this.listDetails.assignee_id,
          process_leads: Number(this.listDetails.client_id),
          process_matter: Number(this.listDetails.matter_id),
         // process_priority: this.listDetails.priority,
          process_status: this.listDetails.process_status,
        });

        if (this.clientList.length > 0) {
          this.selectedClient = this.clientList.filter(
            (c) => Number(c.clientID) === this.listDetails.client_id
          )[0];
        }

        this.getMatterTypeId(this.listDetails.matter_id);
        this.onChange(this.listDetails.assignee_type);
      
      });
  }

  getTaskListID() {
    if (this.subscribes["taskListId"]) {
      this.subscribes["taskListId"].unsubscribe();
    }
    this.subscribes["taskListId"] = this.data
      .getTaskListID()
      .subscribe((data) => {
        this.taskListID = data;
      });
  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        if (this.subscribes["clientList"]) {
          this.subscribes["clientList"].unsubscribe();
        }
        this.assigneeType = "Client";
        this.subscribes["clientList"] = this.data
          .listClientList()
          .subscribe((data) => {
            this.assigneeList = data;

            this.assigneeList = this.assigneeList.sort(
              (a, b) => b.client_id - a.client_id
            );

            if (this.assigneeList.length > 0 && this.listDetails) {
              this.selectedUser = this.assigneeList.filter(
                (a) => Number(a.clientID) === this.listDetails.client_id
              )[0];
            }
          });
      } else {
        if (this.subscribes["taskAssignee"]) {
          this.subscribes["taskAssignee"].unsubscribe();
        }
        this.assigneeType = "Other";
        this.subscribes["taskAssignee"] = this.data
          .getTaskAssignee(value)
          .subscribe((data) => {
            this.assigneeList = data;

            if (this.assigneeList.length > 0 && this.listDetails) {
              this.selectedUser = this.assigneeList.filter(
                (a) => a.member_id === this.listDetails.assignee_id
              )[0];
            }
          });
      }
    } else {
      this.assigneeList = [];
    }
  }

  onChangeChecklist(event){
    return;
    let checklistID = (event.target.value) ? event.target.value : 0;
    this.listCheckListItems(checklistID);
  }
  listClientList() {
    if (this.subscribes["clientList"]) {
      this.subscribes["clientList"].unsubscribe();
    }
    this.subscribes["clientList"] = this.data
      .listClientList()
      .subscribe((data) => {
        this.clientList = data;
        this.clientList = this.clientList.sort(
          (a, b) => b.client_id - a.client_id
        );
        for (var i = 0; i < this.clientList.length; ++i) {
          this.clientList[i].client_name =
            this.clientList[i].client_number +
            " - " +
            this.clientList[i].client_name;
        }
      });
  }
  listCheckList() {
    if (this.subscribes["checkList"]) {
      this.subscribes["checkList"].unsubscribe();
    }
   
    this.subscribes["checkList"] = this.data
      .getCheckList({})
      .subscribe((data: any) => {
        this.checklist = (data.items.length > 0) ? data.items : [];
        
      });
  }

  listCheckListItems(checklist_id){
    if (this.subscribes["checkListItems"]) {
      this.subscribes["checkListItems"].unsubscribe();
    }
   
    this.subscribes["checkListItems"] = this.data
      .getChecklistItems(checklist_id)
      .subscribe((data: any) => {
        this.checklistItems = (data.items.length > 0) ? data.items : [];
        if(checklist_id == 0){
          this.addTaskForm.controls["checklist_item_id"].setValue('');
        }
      });
  }
  listMatterList(client_id) {
    return new Promise((resolve) => {
      if (this.subscribes["mlist"]) {
        this.subscribes["mlist"].unsubscribe();
      }
      this.listParam.client_id = client_id;
      this.subscribes["mlist"] = this.data
        .allMatters(this.listParam)
        .subscribe((data) => {
          this.matterList = data;

          this.matterList = this.matterList.sort(
            (a, b) => b.matter_id - a.matter_id
          );

          for (var i = 0; i < this.matterList.length; ++i) {
            this.matterList[i].matter_name =
              this.matterList[i].matter_number +
              " - " +
              this.matterList[i].matter_name;

            if (
              this.listDetails &&
              this.matterList[i].matter_id == this.listDetails.matter_id
            ) {
              this.selectedMatter = this.matterList[i];
            }
          }
        });
      resolve(null);
    });
  }

  addTask(postData) {
    if (postData.process_due_date != "" && postData.process_due_date != null) {
      var dateSendingToServer = new DatePipe("en-US").transform(
        postData.process_due_date,
        "yyyy-MM-dd"
      );
    } else {
      var dateSendingToServer = "";
    }

    let formdata = new FormData();
    formdata.append("p_id", postData.p_id);
    formdata.append("process_id", postData.process_id);
    formdata.append("checklist_id", postData.checklist_id);
    formdata.append("checklist_item_id", postData.checklist_item_id);
    formdata.append("due_date", dateSendingToServer);
    formdata.append("description", postData.process_description);
    formdata.append("assignee_type", postData.process_assignee);
    formdata.append("assignee_id", postData.process_assignee_user);
    formdata.append("matter_id", postData.process_matter);
    formdata.append("client_id", postData.process_leads);
    //formdata.append("priority", postData.process_priority);
    formdata.append("process_status", postData.process_status);
    formdata.append("add_from", "checklist_process");

    if (postData.p_id) {
      formdata.append("p_id", postData.p_id);
    }

    this.data.addChecklistProcesspost(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.getTaskList();
          this.blankErrorMsgs();
          this.editTaskList();
          setTimeout(() => {
            this.succ_msg = false;
            jQuery("#checklistProcessModal").modal("hide");
            this.clickCancel();
            this.getLastProcessId();
            this.taskFormInit();
          }, this.data.timeOut);
          //this.router.navigate(['/contacts']);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {


        if (error.error.assignee_type) {
          this.err_assignee_type = error.error.assignee_type;
        } else {
          this.err_assignee_type = "";
        }

        if (error.error.assignee_id) {
          this.err_assignee_id = error.error.assignee_id;
        } else {
          this.err_assignee_id = "";
        }

        if (error.error.client_id) {
          this.err_client_id = error.error.client_id;
        } else {
          this.err_client_id = "";
        }

        if (error.error.matter_id) {
          this.err_matter_id = error.error.matter_id;
        } else {
          this.err_matter_id = "";
        }

        if (error.error.checklist_id) {
          this.err_checklist_id = error.error.checklist_id;
        } else {
          this.err_checklist_id = "";
        }
        if (error.error.checklist_item_id) {
          this.err_checklist_item_id = error.error.checklist_item_id;
        } else {
          this.err_checklist_item_id = "";
        }

        
      }
    );
  }

  blankErrorMsgs() {
   this.err_assignee_type =
      this.err_assignee_id =
      this.err_client_id =
      this.err_matter_id =
      this.err_checklist_id = this.err_checklist_item_id = "";
  }

  clickCancel() {
    this.closeModal.emit();
    this.blankErrorMsgs();
    if (this.checklistProcessId == 0) {
      this.getLastProcessId();
    }
  }

  getMatterTypeId(matterId) {
    return new Promise((resolve, reject) => {
      this.data.getMatterDetails(matterId).subscribe((data) => {
        this.matterDtl = data;
      });
      resolve(null);
    });
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      p_id: new FormControl(""),
      process_id: new FormControl(""),
      checklist_id: new FormControl(""),
      checklist_item_id: new FormControl(""),
      process_name: new FormControl(""),
      process_due_date: new FormControl(""),
      process_description: new FormControl(""),
      process_assignee: new FormControl(""),
      process_assignee_user: new FormControl(""),
     // process_priority: new FormControl(""),
      process_leads: new FormControl(""),
      process_matter: new FormControl(""),
      process_status: new FormControl(""),
    });
  }
}
